'use strict';

module.exports = Marionette.View.extend({
    el: '.article-page',
    ui: {
        repostBtn: '.repost-btn'
    },
    events: {
        'click @ui.repostBtn': 'onClickRepost'
    },
    initialize () {
        let _this = this;

        $(window).on('resize', function (e) {
            return _this.controlWidth(e);
        } );

        this.controlWidth();
    },
    getImage () {
       let firstImg = $('.article__content img')[0],
           url = $(firstImg).attr('src');
    },
    onClickRepost (event) {
        event.preventDefault();
        let location = window.location.href,
            image = window.location.host+'/'+ this.getImage(),
            socWeb = $(event.currentTarget).data('socWeb'),
            url = '',
            ptitle = $(event.currentTarget).data('title'),
            text = $(event.currentTarget).data('text');

        switch (socWeb) {
            case 'vk':
                url += 'http://vkontakte.ru/share.php?';
                url += 'url=' + encodeURIComponent(location);
                url += '&title=' + encodeURIComponent(ptitle);
                url += '&description=' + encodeURIComponent(text);
                url += '&image=' + encodeURIComponent(image);
                url += '&noparse=true';
                break;
            case 'fb':
                url += 'http://www.facebook.com/sharer/sharer.php?s=100';
                url += '&p[url]=' + encodeURIComponent(location);
                url += '&p[title]=' + encodeURIComponent(ptitle);
                url += '&p[summary]=' + encodeURIComponent(text);
                url += '&p[images][0]=' + encodeURIComponent(image);
                break;
            case 'tw':
                url += 'http://twitter.com/share?';
                url += 'url=' + encodeURIComponent(location);
                url += '&text=' + encodeURIComponent(ptitle);
                url += '&counturl=' + encodeURIComponent(location);
                break;
            default:
                break;
        }
        this.openWindow(url);
    },
    openWindow (url) {
        window.open(url, '', 'toolbar=0,status=0,width=600,height=440');
    },
    controlWidth (e) {
        let videoFrame = $('.article__content iframe'),
            width = videoFrame.parent().width();
        if (window.outerWidth <= 1200 && window.outerWidth > 768) {
            videoFrame.width(width).height(width / 1.7);
        } else if (window.outerWidth <= 768) {
            videoFrame.width(width).height(width / 1.7)
        } else {
            videoFrame.width(736).height(412)
        }
    }
});

