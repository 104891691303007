import Article from './article';

module.exports = Marionette.View.extend({
    el: '.main-app__container',
    initialize () {
        let _this = this;

        $(window).on('resize', function (e) {
            return _this.controlWidth(e);
        } );
      
        this.controlWidth();

        this.articleView = new Article();
    },
    controlWidth (e) {
        let videoFrame = $('.article__content iframe'),
            width = videoFrame.parent().width();
        if (window.outerWidth <= 1200 && window.outerWidth > 768) {
            videoFrame.width(width).height(width / 1.7);
        } else if (window.outerWidth <= 768) {
            videoFrame.width(width).height(width / 1.7)
        } else {
            videoFrame.width(736).height(412)
        }
    }
});

