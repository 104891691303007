import Controller from './controller';

module.exports = Marionette.AppRouter.extend({
  controller: Controller,
  appRoutes: {
    'portfolio': 'portfolio',
    'uslugi/': 'default',
    'uslugi/:section': 'default',
    'uslugi/:section/': 'default',
    'uslugi/:section/:id': 'service',
    'blog/': 'blog',
    'blog/:id': 'article',
    'contacts': 'default',
    'raschet-stoimosti': 'default',
    'internet-magazinam': 'default',
    '': 'default',
 }
});
