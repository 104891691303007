
const PAGES = {
    article: require('./service'),
    portfolio: require('./portfolio'),
    service: require('./service'),
    blog: require('./blog'),
};

const youTube = Marionette.View.extend({
    el: '.main-app__content',
    ui: {
        modal: '.video-modal',
        video: '.video-modal__video',
        modalOpen: '.youtube-link',
        modalClose: '.video-modal__close',
        modalOverlay: '.video-modal__overlay',
        videoContainer: '.video-modal__container',
    },
    events: {
        'click @ui.modalOpen': 'modalOpen',
        'click @ui.modalClose': 'modalClose',
        'click @ui.modalOverlay': 'modalClose',
    },
    initialize () {
        var _this = this;
        $(window).on('resize', function (e) {
            return _this.controlWidth(e);
        });
  
      $('.photo-page-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 800,
        autoplaySpeed: 3000,
        autoplay: true,
        fade: true,
        cssEase: 'linear'
      });
    },
    modalOpen (e) {
        e.preventDefault();
        let link = $(e.target).attr('href'),
            video = '<iframe class="video-modal__video" src="'+link+'" frameborder="0" allowfullscreen="" style="display: inline;" width="100%" height="100%"></iframe>'
        $(this.ui.videoContainer).html(video);
        this.controlWidth();
        $(this.ui.modal).addClass('show')
    },
    modalClose (e) {
        $(this.ui.modal).removeClass('show');
        $(this.ui.videoContainer).html('');
    },
    controlWidth (e) {
        let videoFrame = $('.video-modal__video'),
            width = videoFrame.parent().width();
        if (window.outerWidth <= 1200) {
            videoFrame.width(width).height(width / 1.8);
        } else {
            videoFrame.width('100%').height(540)
        }
    }
});

export default Marionette.View.extend({
    el: '.main-app__container',
    events: {
        'click .menu__icon': 'showMobileMenu',
    },
    initialize () {
        var _this = this,
            $iFrame = $('.video-frame');

        this.initSlider();

        $(window).on('resize load', function (e) {
            return _this.controlWidth(e);
            this.$el.find('.block-slider').slick('resize');
        } );

        $iFrame.on('load', ()=> {
            $iFrame.css("visibility", "visible");
        });

        this.checkView();
        this.youTubeView = new youTube();
    },
    checkView () {
        let page = this.options.pageView;
        if(_.isFunction(PAGES[page])) {
            this.currentView = new PAGES[page]();
        }
    },
    initSlider () {
        this.$el.find('.block-slider').slick({
            mobileFirst: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        variableWidth: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        variableWidth: false,
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        variableWidth: false,
                        slidesToShow: 1,
                    }
                }
            ]
        });
    },
    controlWidth (e) {
        let videoFrame = $('.video-frame'),
            width = videoFrame.parent().width();
        if (window.outerWidth <= 1200 && window.outerWidth > 768) {
            videoFrame.width(width - 30).height(width / 1.7);
        } else if (window.outerWidth <= 768) {
            videoFrame.width(width).height(width / 1.7)
        } else {
            videoFrame.width(633).height(360)
        }
        if (window.outerWidth >= 992) $('body').css({'overflow':'auto'});
    },
    showMobileMenu (e) {
        let $header = this.$el.find('.main-app__header'),
            $body = $('body');
        $header.toggleClass('show');
        if ($header.hasClass('show')) {
            $body.css({'overflow':'hidden'});
            $(e.target).addClass('i-close-button').removeClass('i-menu-button')
        }  else {
            $body.css({'overflow':'auto'});
            $(e.target).removeClass('i-close-button').addClass('i-menu-button')
        }
    }
});

