
const Form = Marionette.View.extend({
    el: '.feedback',
    ui: {
        form: '.feedback__form',
        formBtn: '.feedback__button',
        formError: '.feedback__error',
        personal: '.feedback__personal',
        return: '.feedback__return',
        fieldName: '#f-name',
        fieldPhone: '#f-phone',
        fieldCompany: '#f-company',
        fieldSite: '#f-site',
        formCont: '.feedback__main',
        formAgree: '.feedback__agree',
    },
    events: {
        'click @ui.return': 'showText',
        'click @ui.personal': 'showText',
        'submit @ui.form': 'sendForm',
        'input @ui.fieldPhone': 'setNumber',
    },
    setNumber (e) {
       let phone = $(e.target);
        if (phone.val().match(/[^0-9+()/-]/g)) {
            phone.val(phone.val().replace(/[^0-9+()/-]/g, ''));
        }
    },
    getData () {
        let data = {};
        _.each($('.feedback__input'), (input, index)=> {
            data[$(input).attr('name')] = _.escape($(input).val().trim());
        });
        return data;
    },
    sendForm (e) {
        e.preventDefault();
        $(this.ui.formBtn).prop("disabled", true);
        let form = $(this.ui.form),
            data = this.getData();
        $('.feedback__error').siblings('input').removeClass('error');
        $('.feedback__error').remove();
        $.ajax({
            type: "post",
            url: "mail.php",
            dataType: "json",
            data: data
        }).done((result, status)=> {
            if (status === 'success') {
                if (result.status === false) {
                    _.each(result.errors, (obj, key)=> {
                        $('input[name="'+ obj.field +'"]').addClass('error').parent().append('<span class="feedback__error">'+ obj.text +'</span>')
                    })
                } else if (result.status === true) {
                    form.trigger("reset");
                    this.$el.html('<div class="feedback__thx">\n' +
                        '<h3 class="thx__title">Спасибо!</h3>\n' +
                        '<h4 class="thx__subtitle">Наш менеджер свяжется с Вами в ближайшее время</h4>\n' +
                        '<p class="thx__text">Время работы: 10:00 - 19:00 (по будням) <br>\n' +
                        'Телефон: +7 (926) 120-43-60 <br>\n' +
                        'info@customvideo.ru</p></div>');
                }
            } else {
                console.log(result, status);
            }
            $(this.ui.formBtn).prop("disabled", false);
        });
        return false;
    },
    showText () {
        $(this.ui.formCont).toggleClass('hide');
        $(this.ui.formAgree).toggleClass('hide');
    }
});


module.exports = Marionette.View.extend({
    el: '.main-app__container',
    ui: {
        showBtn: '.f-modal',
        hideBtn: '.modal__close',
        overlay: '.modal__overlay',
        modal: '.modal'
    },
    events: {
        'click @ui.showBtn': 'toggleModal',
        'click @ui.hideBtn': 'removeModal',
        'click @ui.overlay': 'removeModal',
    },
    initialize () {
        this.form = new Form();
    },
    toggleModal (e) {
        e.preventDefault();
        $(this.ui.modal).toggleClass('show');
    },
    removeModal (e) {
        e.preventDefault();
        $(this.ui.modal).removeClass('show');
    }
});