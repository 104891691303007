import LayoutView from './views/layout';

export default {
  article (options) {
    app.currentView = new LayoutView({
      pageView: 'article'
    });
  },
  service (options) {
    app.currentView = new LayoutView({
      pageView: 'service'
    });
  },
  portfolio (options) {
    app.currentView = new LayoutView({
      pageView: 'portfolio'
    });
  },
  blog (options) {
    app.currentView = new LayoutView({
      pageView: 'blog'
    });
  },
  default (options) {
    app.currentView = new LayoutView({
      pageView: 'default'
    });
  },
}