
module.exports = Marionette.View.extend({
  el: '.portfolio-page',
  ui: {
    filterItem: '.filter__item',
  },
  events: {
    'click @ui.filterItem': 'getPortfolioItems',
  },
  initialize() {
    $(document).on('scroll', $.proxy(this.addNewItems, this));
    
    var hash = window.location.hash;
    
    if (hash) {
      this.getPortfolioItems(hash);
    }
  },
  getPortfolioItems(data) {
    var url = 'portfolio';
    var itemCategory = null;
    
    if (typeof data === 'string') {
      itemCategory = data.slice(1);
    } else {
      var target = data.target;
      itemCategory = $(target).data('cat');
    }

    url += '?parentCat=' + itemCategory;
    
    this.ajaxSend(url);
    
    $('.filter__item').removeClass("active");
    $('.filter__item[data-cat="' +itemCategory +'"]').addClass("active");
  },
  ajaxSend(url) {
    $.ajax({
      method: 'GET',
      url: url,
    }).done((response) => {
      var $response = $(response);
      
      $('.ajax-container').hide();
      $('.ajax-container').html($response.find('.ajax-container').html()).show();
    });
  },
  addNewItems() {
    if ($('.ajax-more').length) {
      var offset = $('.ajax-item').length,
        count = $('.ajax-filter-count').data('count'),
        top = $('.ajax-more').offset().top,
        winBottom = $(window).scrollTop() + $(window).outerHeight();
    }
    if ( top <= winBottom && offset < count ) {
      $('.ajax-more').remove();
      $.ajax({
        data: 'portfolio' + '&offset='+offset
      }).done((response) => {
        var $response = $(response);
        $response.find('.ajax-item').hide();
        $('.ajax-container').append($response.find('.ajax-container').html());
        $('.ajax-item').show();
      });
    }
  },
});