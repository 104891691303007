import Router from './router';
import Modal from './views/modal.form';

export default Marionette.Application.extend({
	el: '#main-app',
  constructor: function constructor() {
    this.router = new Router();
    this.modal = new Modal();
  },
  start() {
    Backbone.history.start({pushState:true});
	}
});