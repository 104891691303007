
module.exports = Marionette.View.extend({
    el: '.blog-page',
    ui: {
        showBtn: '.blog-page__signup',
        icons: '.blog-page__social'
    },
    events: {
        'click @ui.showBtn': 'showSocial',
    },
    showSocial (e) {
        e.preventDefault();
        $(e.target).remove();
        $(this.ui.icons).addClass('active');
    }
});